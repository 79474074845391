$(document).on('turbolinks:load', function() {
	addEventListener("trix-file-accept", function(event) {
		// Prevent attaching .png files
		mime_type_blacklist = ["video/x-flv", "video/mp4", "application/x-mpegURL", "video/MP2T", "video/3gpp", "video/quicktime", "video/x-msvideo", "video/x-ms-wmv"];
		if (mime_type_blacklist.includes(event.file.type)) {
			event.preventDefault();
			alert("Der Dateityp wird nicht unterstützt.");
		}
	})

	function selectHotspot(e) {
		const clickedHotspot = e.target.parentElement;
		const container = clickedHotspot.parentElement;
		
		// only include hotspots within same image to allow one open hotspot per image; change "container" to "document" to allow only one open hotspot for entire page:
		const hotspots = container.querySelectorAll(".lg-hotspot"); 
		hotspots.forEach(hotspot => {
			if (hotspot === clickedHotspot) {
				hotspot.classList.toggle("lg-hotspot--selected");
			} else {
				hotspot.classList.remove("lg-hotspot--selected");
			}
		});
	}

	(() => {
		const buttons = document.querySelectorAll(".lg-hotspot__button");
		buttons.forEach(button => {
			button.addEventListener("click", selectHotspot);
		});
	})();
});
