import { Controller } from "stimulus"
import Typed from 'typed.js'

export default class extends Controller {
  static targets = []

  connect() {
    var options = {
      strings: ['Ihre Ideen.', 'Unsere Erfahrung.', 'Praxisnahe Softwareprodukte', 'innerhalb weniger Wochen.'],
      typeSpeed: 80,
      backDelay: 2000,
      fadeOut: true,
      startDelay: 1000,
      loop: true
    };

    var typed = new Typed('h2.typed', options);
  }

}
