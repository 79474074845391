import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "icon" ]

  connect() {
    //const icons = this.iconTargets;
  }

  addbg() {
    const bg = event.target.dataset.bg;
    event.target.style.background = bg;
  }

  removebg() {
    const bg = event.target.dataset.bg;
    event.target.style.background = "none";
  }

}
