import { Controller } from "stimulus"
import Typed from 'typed.js'

export default class extends Controller {
  static targets = []

  connect() {
    var options = {
      strings: ["Hallo, wir arbeiten viel mit Excel-Tabellen und da geht häufig einiges schief...", "Guten Tag, wir sind ein Architekturbüro und suchen eine Software zur Zeiterfassung und Projektplanung...", "Guten Tag, wir sind eine Fußballschule und sind auf der Suche nach einer Software zum Buchungsmanagement...", "Guten Tag, wir sind eine Universität und suchen nach einer Lösung zum Management unserer Sprachtandems..."],
      typeSpeed: 30,
      backDelay: 1000,
      startDelay: 0,
      loop: true
    };

    var typed = new Typed('.element--typed-message', options);
  }

}
