// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input" ]

  connect() { }

  reset_hotspot_top() {
    const hotspotid = event.target.dataset.hotspotid;
		const button = document.getElementById("hotspot-"+hotspotid);
    //button.style.property = event.target.value;
    button.style.top = event.target.value+"%";
    console.log(button);
  }

  reset_hotspot_left() {
    const hotspotid = event.target.dataset.hotspotid;
		const button = document.getElementById("hotspot-"+hotspotid);
    //button.style.property = event.target.value;
    button.style.left = event.target.value+"%";
    console.log(button);
  }
}
